import Flickity from 'flickity';

class CarouselBlock {
	constructor(block) {
		this.block = block;
		this.itemsWrapper = block.querySelector('.carousel-block__items');
		this.items = block.querySelectorAll('.carousel-block__item');

		this.enableAutoplay = this.block.dataset.enableAutoplay === 'true';
		this.autoplaySpeed = parseInt(this.block.dataset.autoplaySpeed) || 5000;

		this.flickityInit();
	}

	flickityInit() {
		const isMobile = window.matchMedia('(max-width: 768px)').matches;
		this.flkty = new Flickity(this.itemsWrapper, {
			pageDots: isMobile ? true : this.items.length > 3,
			prevNextButtons: false,
			// wrapAround: true,
			// autoPlay: false, //5000,
			autoPlay: this.enableAutoplay ? this.autoplaySpeed : false,
			// adaptiveHeight: true,
			contain: true,
			cellAlign: 'left',
			imagesLoaded: true,
			groupCells: true,
		});
	}
}

export default CarouselBlock;
