class TextVideo {
	constructor(block) {
		this.block = block;
		this.body = document.querySelector('body');
		this.video = block.querySelector('.text-video__video');
		this.thumbnail = block.querySelector(
			'.text-video__video-thumbnail-wrapper'
		);
		this.videoContainer = block.querySelector('.text-video__video-container');

		this.events();
	}

	events() {
		this.video?.addEventListener('click', (e) => this.openVideoModal(e));
		this.thumbnail?.addEventListener('click', (e) => this.playInlineVideo(e));
	}

	playInlineVideo(e) {
		e.preventDefault();

		this.thumbnail.classList.add('hidden');

		this.videoContainer.classList.remove('hidden');

		const video = this.videoContainer.querySelector('video');
		if (video) {
			video.play();
		}

		const iframe = this.videoContainer.querySelector('iframe');
		if (iframe) {
			const src = iframe.getAttribute('src');
			iframe.setAttribute('src', src + '&autoplay=1');
		}
	}
	openVideoModal(e) {
		e.preventDefault();
		document.addEventListener('keyup', this.modalKeypressHandler);

		// Create modal
		this.videoModal = document.createElement('div');
		this.videoModal.classList.add('fd-modal', 'text-video__modal');

		// Create close button
		const closeButton = document.createElement('button');
		closeButton.classList.add('text-video__modal-close');
		closeButton.innerHTML = '<i class="fa-solid fa-xmark"></i>';
		closeButton.addEventListener('click', () => this.closeVideoModal());
		this.videoModal.appendChild(closeButton);

		// Create video embed
		if (this.video.dataset.type == 'self') {
			const video = document.createElement('video');
			video.setAttribute('controls', '');
			video.setAttribute('autoplay', '');
			video.classList.add('text-video__modal-video');
			video.innerHTML = `<source src="${this.video.dataset.src}" type="${this.video.dataset.mimetype}">`;
			this.videoModal.appendChild(video);
		} else {
			let source = '';
			if (this.video.dataset.type == 'youtube') {
				source = `https://www.youtube.com/embed/${this.video.dataset.src}?controls=0&autoplay=1&loop=1&playlist=${this.video.dataset.src}`;
			}
			if (this.video.dataset.type == 'vimeo') {
				source = `https://player.vimeo.com/video/${this.video.dataset.src}?controls=0&autoplay=1&loop=1`;
			}

			const iframe = document.createElement('iframe');
			iframe.classList.add('text-video__modal-video');
			iframe.setAttribute('frameborder', '0');
			iframe.setAttribute('allow', 'autoplay; encrypted-media');
			iframe.setAttribute('allowfullscreen', '');
			iframe.setAttribute('src', source);
			this.videoModal.appendChild(iframe);
		}

		// Add modal to page
		this.body.appendChild(this.videoModal);
	}

	closeVideoModal() {
		this.videoModal.classList.add('text-video__modal--closing');

		setTimeout(() => {
			this.videoModal.remove();
			document.removeEventListener('keyup', this.modalKeypressHandler);
		}, 250);
	}

	modalKeypressHandler = (e) => {
		if (e.keyCode == 27) {
			this.closeVideoModal();
		}
	};
}

export default TextVideo;
