import Chart from "chart.js/auto"
import ChartDeferred from "chartjs-plugin-deferred"
Chart.register(ChartDeferred)

class Results {
  constructor(block) {
    this.block = block
    this.charts = block.querySelectorAll(".results-block__chart")
    this.chartsInit()
  }

  chartsInit() {
    this.charts.forEach((chart) => {
      let data = []
      if (chart.dataset.type == "percentage") {
        data = [chart.dataset.percentage, 100 - chart.dataset.percentage]
      } else if (chart.dataset.type == "ratio") {
        data = [chart.dataset.ratio1, chart.dataset.ratio2]
      }

      const handleResize = (chart) => {
        chart.resize()
      }

      new Chart(chart, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data,
            },
          ],
        },
        options: {
          backgroundColor: ["#37d1b7", "#8decdc"],
          borderWidth: 0,
          events: [],
          plugins: [ChartDeferred],
          responsive: true,
          maintainAspectRatio: true,
          onResize: handleResize,
        },
      })
    })
  }
}

export default Results
