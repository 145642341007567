import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import Header from './components/header';
import TextVideo from './components/textVideo';
import CarouselBlock from './components/carouselBlock';
import PodcastPlayback from './components/podcastPlayback';
import Results from './components/results';
import TestimonialsCarousel from './components/testimonialCarousel';
import TextForm from './components/textForm';

for (const header of document.querySelectorAll('.site-header'))
	new Header(header);
for (const block of document.querySelectorAll('.text-video'))
	new TextVideo(block);
for (const block of document.querySelectorAll('.carousel-block'))
	new CarouselBlock(block);
for (const block of document.querySelectorAll('.podcast-playback'))
	new PodcastPlayback(block);
for (const block of document.querySelectorAll('.results-block'))
	new Results(block);
for (const block of document.querySelectorAll('.testimonials-carousel'))
	new TestimonialsCarousel(block);
for (const block of document.querySelectorAll('.text-form'))
	new TextForm(block);

// header and footer heights
document.addEventListener('DOMContentLoaded', () => {
	function updateHeights() {
		const header = document.querySelector('.site-header');
		const footer = document.querySelector('.site-footer');

		if (header) {
			document.documentElement.style.setProperty(
				'--header-height',
				`${header.offsetHeight}px`
			);
		}

		if (footer) {
			document.documentElement.style.setProperty(
				'--footer-height',
				`${footer.offsetHeight}px`
			);
		}
	}

	window.addEventListener('load', updateHeights);
	window.addEventListener('resize', updateHeights);
});
