import _ from "lodash"

class Header {
  constructor(header) {
    //Elements
    this.header = header
    this.body = document.querySelector("body")
    this.uploadButtons = document.querySelectorAll(".site-header__upload")
    this.mobileMenuButton = header.querySelector(".site-header__mobile-menu-button")
    this.uploadModal = document.querySelector(".upload-cv-modal")
    this.closeUploadModalButton = this.uploadModal.querySelector(".upload-cv-modal__close")
    this.mobileMenu = document.querySelector(".mobile-menu")
    this.mobileSubmenus = document.querySelectorAll(".mobile-menu__nav-sublist-wrapper")
    this.mobileMenuLinks = document.querySelectorAll(".mobile-menu__nav-list-item-link")

    //State
    this.uploadModalOpen = false

    //Methods
    this.setScrolled()
    this.events()
  }

  events() {
    window.addEventListener(
      "scroll",
      _.throttle((e) => {
        this.setScrolled(e)
      }, 250)
    )

    window.addEventListener(
      "resize",
      _.debounce(() => {
        this.setScrolled()
      }, 250)
    )

    this.mobileMenuButton.addEventListener("click", (e) => {
      this.toggleMobileMenu(e)
    })

    this.mobileSubmenus.forEach((submenu) => {
      submenu.querySelector(".mobile-menu__nav-sublist-back")?.addEventListener("click", () => {
        this.closeSubmenu(submenu)
      })
    })

    this.mobileMenuLinks.forEach((link) => {
      link.addEventListener("click", (e) => {
        if (link.dataset.id != 0) {
          e.preventDefault()
          this.openSubmenu(link.dataset.id)
        } else {
          console.log(`Go to ${link.href}`)
        }
      })
    })

    this.uploadButtons.forEach((button) => {
      button.addEventListener("click", () => this.openUploadModal())
    })

    this.closeUploadModalButton.addEventListener("click", () => this.closeUploadModal())

    document.addEventListener("keyup", this.keypressHandler)
  }

  openUploadModal() {
    this.uploadModal.classList.add("upload-cv-modal--active")
    this.uploadModalOpen = true
  }

  closeUploadModal() {
    this.uploadModal.classList.remove("upload-cv-modal--active")
    this.uploadModalOpen = false
  }

  openSubmenu(id) {
    this.mobileSubmenus.forEach((submenu) => {
      submenu.classList.toggle("mobile-menu__nav-sublist-wrapper--active", submenu.dataset.id == id)
    })
  }

  closeSubmenu(submenu) {
    submenu.classList.remove("mobile-menu__nav-sublist-wrapper--active")
  }

  toggleMobileMenu(e) {
    this.mobileMenuButton.classList.toggle("site-header__mobile-menu-button--active")
    this.mobileMenu.classList.toggle("mobile-menu--active")
  }

  setScrolled() {
    this.header.classList.toggle("site-header--scrolled", window.scrollY > 0)
  }

  keypressHandler = (e) => {
    if (e.keyCode == 27) {
      if (this.uploadModalOpen) this.closeUploadModal()
    }
  }
}

export default Header
