class PodcastPlayback {
  constructor(block) {
    this.block = block
    this.init()
  }

  init() {
    window.onSpotifyIframeApiReady = (IFrameAPI) => {
      const element = document.querySelector(".podcast-playback__inner")
      const options = {
        uri: `spotify:episode:${this.block.dataset.podcast}`,
        width: "100%",
        height: "160px",
      }
      const callback = (EmbedController) => {}
      IFrameAPI.createController(element, options, callback)
    }
  }
}

export default PodcastPlayback
