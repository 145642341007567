class TextForm {
  constructor(block) {
    this.block = block
    this.selects = block.querySelectorAll("select.wpcf7-select")
    this.disableFirstSelectOption()
  }

  disableFirstSelectOption() {
    this.selects.forEach((select) => {
      select.querySelector("option[value='']").setAttribute("disabled", "disabled")
    })
  }
}

export default TextForm
