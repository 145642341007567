import Flickity from "flickity"

class TestimonialsCarousel {
  constructor(block) {
    this.block = block
    this.itemsWrapper = block.querySelector(".testimonials-carousel__items")
    this.items = block.querySelectorAll(".testimonials-carousel__item")

    if (this.items.length > 1) {
      this.flickityInit()
    }
  }

  flickityInit() {
    this.flkty = new Flickity(this.itemsWrapper, {
      pageDots: true, //this.block.dataset.type == "images",
      prevNextButtons: false,
      wrapAround: true,
      autoPlay: 5000,
      // adaptiveHeight: true,
      contain: true,
      cellAlign: "left",
      imagesLoaded: true,
      //   groupCells: true,
    })
  }
}

export default TestimonialsCarousel
